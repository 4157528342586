$primary: #9f284d;

$active-color: $primary;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~@bootstrap-extended-style/bootstrap-extended-style/index";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/duotone";

body {
  background-color: black;
  color: #fefefe;
}

.card-dark {
  background-color: #111;
  color: white;
  .text-muted {
    color: #888 !important;
  }
}
